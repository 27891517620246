/**
=========================================================
* 508Media TikTok Tool - v2.2.0
=========================================================

* Product Page: https://www.egeadcompany.com/product/material-dashboard-react
* Copyright 2023 Dev Egead Company (https://www.egeadcompany.com)

Coded by www.egeadcompany.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Box, Button, TextField } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDPagination from 'components/MDPagination';
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { checkproxy } from "features/slices";
import { getTiktok } from "features/slices";
import LoadingScreen from "Loading";
import moment from "moment";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";

function Proxy() {
  const [data, setData] = useState([1, 2, 3]);
  const isLoading = useSelector((state) => state.user.isLoading);
  const [proxy, setProxy] = useState([]);
  const dispatch = useDispatch()
  const handleCheckProxy = async () => {
    console.log(proxy.split("\n"))
    const res = await dispatch(checkproxy({ data: proxy.split("\n") }))
    if (res.payload) {
      setData(res.payload)
    }
  };

  return (
    <DashboardLayout>
      {isLoading && <LoadingScreen />}
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <TextField
                  label="Nhập url hình ảnh (mỗi giá trị 1 dòng)"
                  value={proxy}
                  onChange={(e) => setProxy(e.target.value)}
                  sx={{ margin: '10px', width: "100%" }}
                  multiline
                  rows={7}
                />
              </Box>
              <Box>
                <Button variant="contained" sx={{ margin: 5 }} onClick={handleCheckProxy}>
                  Check Proxy
                </Button>
              </Box>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns: [
                      { Header: "stt", accessor: "stt", align: "left", width: "1%" },
                      { Header: "proxy", accessor: "proxy", align: "left" },
                      { Header: "ip", accessor: "ip", align: "left" },
                      { Header: "country", accessor: "country", align: "left" },
                      { Header: "score", accessor: "score", align: "left" },
                    ],
                    rows: data && data.length > 0 ? [...data.map((items, index) => ({
                      stt: <>{items[0]}</>,
                      proxy: <>{items[1]}</>,
                      ip: <>{items[2]}</>,
                      score: <>{items[3]}</>,
                      country: <>{items[4]}</>,
                    })
                    )] : []
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>


      <Footer />
    </DashboardLayout >
  );
}

export default Proxy;
